<template>
  <div class="pb-20">
    <h3 class="title is-3 mb-5 dark-text line-height-2">
      Reveal Your Hidden Revenue Potential
    </h3>
    <h5 class="title is-5 mb-5 line-height-2">
      See how many people are looking to BUY your product outside of Amazon!
    </h5>
  </div>
  <form>
    <div class="field">
      <label class="form-label">Enter your ASIN</label>
      <div class="control">
        <input type="text"
               v-model="form.ASIN"
               :disabled="fetchingData"
               class="input is-medium" />
      </div>
    </div>
    <div class="field">
      <label class="form-label">Enter your email</label>
      <div class="control">
        <input type="text"
               v-model="form.Email"
               :disabled="fetchingData"
               class="input is-medium" />
      </div>
    </div>
    <h5 class="title is-5 mt-30 mb-5">Enter your top 3 keywords</h5>

    <div class="field">
      <label class="form-label">Enter your first keyword</label>
      <div class="control">
        <input type="text"
               v-model="form.Keywords1"
               :disabled="fetchingData"
               class="input is-medium" />
      </div>
    </div>
    <div class="field">
      <label class="form-label">Enter your second keyword</label>
      <div class="control">
        <input type="text"
               v-model="form.Keywords2"
               :disabled="fetchingData"
               class="input is-medium" />
      </div>
    </div>
    <div class="field">
      <label class="form-label">Enter your third keyword</label>
      <div class="control">
        <input type="text"
               v-model="form.Keywords3"
               :disabled="fetchingData"
               class="input is-medium" />
      </div>
    </div>

    <div class="mt-30">
      <button type="submit"
              @click.prevent="submit"
              :disabled="fetchingData"
              class="button btn-align button-cta no-lh is-bold primary-btn raised font-size-small">
        Show me how much money I'm leaving on the table!
      </button>
    </div>
  </form>
</template>

<script>
export default {
  data() {
    return {
      fetchingData: false,
      form: {
        ASIN: '',
        Email: '',
        Keywords1: '',
        Keywords2: '',
        Keywords3: '',
      }
    }
  },
  mounted() {
    const urlParams = new URLSearchParams(window.location.search);
    const asin = urlParams.get('asin');

    if (asin) {
      this.form.ASIN = asin;
    }
  },
  methods: {
    submit() {
      this.fetchingData = true;

      this.axios.post('/free-report', this.form)
          .then(res => {
            this.$emit('newData', res.data.data);
          })
          .finally(() => {
            this.fetchingData = false;
          })
    }
  }
}
</script>